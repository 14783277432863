import React from 'react'
import Helmet from 'react-helmet'
import BannerSolutions from '../components/BannerSolutions'
import Layout from '../components/layout'


const Generic = (props) => (
              <Layout>
                <Helmet
                    title="What we offer"
                    meta={[
                        { name: 'description', content: 'Solutions Page' },
                        { name: 'keywords', content: 'Solutions Page' },
                    ]}
                >
                </Helmet>

        <BannerSolutions />


        <div id="main" className="alt">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h1>Continous innovation</h1>
                    </header>
                    <p>
In <b>Nubizzi</b> we are experts in delivering practical engineering & consultancy solutions to transform and accelerate the way that organizations deliver software.
</p>

<p>
We believe that DevOps practices and disrupting technologies like the cloud offer a new operating model for IT departments to deliver software at speed. This enables innovation which drives competitive advantage.
</p>
                    <p>New companies like <i>Amazon</i>, <i>Uber</i>, and <i>Netflix</i> are showing how the future looks like. And they are shaking the traditional players in the market. But these companies are no different than any other company. They just excel in:</p>
                    <ul>
                    <li>Having a clear business objectives/targets.</li>
                    <li>Being by principle agile in <b>bringing innovation to the final client.</b></li>
                    </ul>
                    <p>And this means changing whatever that needs to be changed in order to deliver this continuous innovation to the client.</p> 
                    <p>A continuous innovation company is able to develop and deploy changes order of magnitude faster (days, hours, minutes) than traditional companies.</p>
                    <ul>
		    <em>How long does it take to create a new product or service?</em><br />
                    <em>
		    How long does it take to move a change of one line of code from development to production?</em></ul>
		    <p>The IT of traditional companies is not up to this challenge. There are many stoppers around the organization.</p>
		    <p>There is a revolution in IT on all fronts: <b>development, infrastructure, and processes</b>. It is happening as we speak. 
Those companies that embrace this revolution first will have a competitive advantage over bigger and traditional companies and will have the potential to disrupt the market.</p>
                    <ul><p><strong>Do you want to be such a disrupter in your market?</strong></p></ul>

	<p>
We work in partnership with our clients to define, build and deploy new services that follow these disruptive patterns and we ensure that they are aligned to their business transformation objectives and priorities. 
	</p>

	<p>
We are open to different business collaboration models, but we promote models where we shared costs, risks, and benefits with our customers to achieve real win-win partnerships. 
	</p>

	<p>
What follows is an example of what we can do for your company:
	</p>
                    <header className="major">
                        <h2>Create o migrate to a Native Cloud Application</h2>
                    </header>
<p>
If you are thinking about developing a new service or migrate your existing software to the cloud, <b>Nubizzi</b> can be a key player in this process. There are several potential collaboration, from a pure consulting service to a model where <b>Nubizzi</b> does the implementation and operation for you for a fee based on usage. In this model we can share the risks of such development and the exploitation of the service: you will have a service that can be easily sold with a fixed cost per transaction and we provide a scalable and reliable service on top of which based your business. 
</p>
                    <header className="major">
                        <h3>Why a Native Cloud Application?</h3>
                    </header>
                    <ul>
                    <strong>Speed</strong><br /><ul>The ability to innovate, experiment, and deliver value faster than competitors.</ul>
                    <strong>Safety</strong><br /><ul>The ability maintain stability, availability, and durability in a faster develop-deploy lifecycle</ul>
                    <strong>Scale</strong><br /><ul>The ability to elastically respond to changes in demand.</ul>
                    <strong>Mobility</strong><br /><ul>The ability for customers to interact with the system seamlessly from any location, on any device, and at any time.</ul>
                    </ul>

                    <header className="major">
                        <h3>How?</h3>
                    </header>
	            <ul>
	            <strong>Twelve-factor applications</strong><br /><ul>A set of patterns that optimize application design for speed, safety, and scale.</ul>
	            <strong>Microservices</strong><br /><ul>An architecture pattern that helps align units of deployment with business capabilities, allowing each capability to change/evolve independently, autonomously,  quickly and safely.</ul>
	            <strong>Self-service agile infrastructure</strong><br /><ul>Cloud platforms that enable development teams to operate in an application and service abstraction level, providing infrastructure-level speed, safety, and scale.</ul>
	            <strong>API-based collaboration</strong><br /><ul>An architecture pattern that defines service-to-service interaction as automatically verifiable contracts, enabling speed and safety through simplified integration work.</ul>
	            <strong>Robustness</strong><br /><ul>As stress  increases on the system, via speed and scale, the system maintains its ability to perform  reliably.</ul>
	            </ul>

<p>
It is not only about the technology. It is also, and as important, about the people. 
These disruptive patterns require new skills and a new mind frame in the team members:</p> 
<ul><li>Multidisciplinary (business and technical, development and operational)</li> 
<li>Always consider your customers’ perspective </li>
<li>Consistently work to improve customer satisfaction</li> 
<li>Full accountability</li></ul> 

                    <header className="major">
                        <h3>Why <b>Nubizzi</b>?</h3>
                    </header>
<ul><li>Being a greenfield organization, it is born with clear principles in place to serve the purpose of continuous delivery, avoiding any obstructive blocks/silos.</li>
<li>With a background of many years in the IT industry, it has the business knowledge and technical skills to offer prepacked and production ready cloud-native frameworks for DevOps.</li>
<li>But most important, and something that cannot be found in other solutions: the capability to inject innovation at fast speed into the solution, driven by
business, to be at the top of the game.</li>
</ul>
                </div>
            </section>
        </div>

    </Layout>
)

export default Generic
