import React from 'react'

const BannerSolutions = (props) => (
    <section id="banner" className="style2">

        <div className="inner">
            <header className="major">
                <h1>Solutions</h1>
            </header>
            <div className="content">
                <p>Nubizzi Services Overview</p>
            </div>
        </div>
    </section>
)

export default BannerSolutions
